'use client';

import { useRouter } from 'next/router';
import { CSSProperties } from 'react';

import useTrackClientError from '~/analytics/hooks/useTrackClientError';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import title1Styles from '~/components/molecules/TextLockups/title1Block.module.css';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import styles from './page.module.css';

export default function Error({ error }: { error: Error }) {
  const router = useRouter();

  useTrackClientError(error);

  // TODO: Need to ensure design library is listing this error component
  return (
    <div className={styles.error}>
      <div
        className={cn(title1Styles.content, 'textLockupCenter')}
        style={
          {
            '--title-1-column-span': 10,
          } as CSSProperties
        }
      >
        <h2 className={cn(title1Styles.titles, styles.errorTitle)}>
          {dict('clientSideErrorTitle')}
        </h2>
        <p className={cn(title1Styles.bodies, styles.errorMessage)}>
          {dict('clientSideErrorMessage')}
        </p>
        <div className={cn(title1Styles.types, styles.errorButtonsGroup)}>
          <Button
            buttonVariant="primary"
            buttonColorScheme="glass"
            onClick={() => {
              router.reload();
            }}
          >
            {dict('reload')}
          </Button>
          <Button
            buttonVariant="primary"
            buttonColorScheme="glass"
            to={{
              type: 'external',
              url: 'https://support.frame.io/',
            }}
          >
            {dict('support')}
          </Button>
        </div>
      </div>
    </div>
  );
}
